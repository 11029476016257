.backdrop {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.backdrop.open {
  opacity: 1;
}

.backdrop.closed {
  opacity: 0;
  visibility: hidden;
}
