html a {
  text-decoration: none;
}

body {
  margin: 0;
  background-color: #f4f5f7;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background: #f1f1f1;
}
*::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px;
}
*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 992px) {
  *::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
}
