.answer {
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.answer.selected {
  background-color: #E6F7FF;
} 

.answer:hover {
  background-color: #E6F7FF;
}