.answer-view {
  width: 100%;
  border-radius: 5px;
  padding: 5px;
}

.answer-view.correct {
  background-color: #F6FFED;
} 
.answer-view.wrong {
  background-color: #FFF2F0;
} 
